import { HeadingMd } from '/features/buildingBlocks/Heading'
import { Icon } from '/features/buildingBlocks/Icon'
import { BoxWithDoubleBorder } from '/features/buildingBlocks/BoxWithDoubleBorder'
import { useTranslate } from '/machinery/I18n'
import styles from './Checklist.css'

import iconCheckmark from '/images/icons/checkmark.raw.svg'

export function Checklist({ children }) {
  const { __ } = useTranslate()

  return (
    <div className={styles.component} data-x='checklist' data-style-context='white'>
      <BoxWithDoubleBorder>
        <div className={styles.layout}>
          <HeadingMd h='2' title={ __`list-item-title` } layoutClassName={styles.heading} />
          <ul className={styles.listItems}>
            {React.Children.map(children, (child, i) => (
              <li className={styles.listItem} key={i}>
                <Icon icon={iconCheckmark} layoutClassName={styles.icon} />
                <span className={styles.label}>{child}</span>
              </li>
            ))}
          </ul>
        </div>
      </BoxWithDoubleBorder>
    </div>
  )
}
