import { PortableText } from '@portabletext/react'
import { determineDocumentPathSync } from '@kaliber/sanity-routing/sanity'
import { LinkText } from '/features/buildingBlocks/Link'
import { routeMap } from '/routeMap'
import { HeadingLg, HeadingMd } from './Heading'
import { Quote } from '/features/buildingBlocks/Quote'
import { Checklist } from '/features/pageOnly/Checklist'
import styles from './PortableText.css'

export function PortableTextMainContent({ value, layoutClassName = undefined }) {
  return (
    <PortableTextBase
      className={cx(styles.componentMainContent, layoutClassName)}
      components={componentsMainContent}
      {...{ value }}
    />
  )
}

export function PortableTextMainContentSmall({ value, layoutClassName = undefined }) {
  return (
    <PortableTextBase
      className={cx(styles.componentMainContentSmall, layoutClassName)}
      components={componentsMainContent}
      {...{ value }}
    />
  )
}

export function PortableTextSimple({ value, layoutClassName = undefined }) {
  return (
    <PortableTextBase
      className={cx(styles.componentSimple, layoutClassName)}
      components={componentsSimple}
      {...{ value }}
    />
  )
}

export function PortableTextSimpleWithLinks({ value, layoutClassName = undefined }) {
  return (
    <PortableTextBase
      className={cx(styles.componentSimpleWithLinks, layoutClassName)}
      components={componentsSimpleWithLinks}
      {...{ value }}
    />
  )
}

export function PortableTextFooterContent({ value, layoutClassName = undefined }) {
  return (
    <PortableTextBase
      className={cx(styles.componentFooterContent, layoutClassName)}
      components={componentsFooterContent}
      {...{ value }}
    />
  )
}

function PortableTextBase({ value, components, className, layoutClassName = undefined }) {
  return <div className={cx(className, layoutClassName)}><PortableText {...{ value, components }} /></div>
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsMainContent = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <HeadingLg h={2} title={children} layoutClassName={styles.heading} />,
    subheading: ({ children }) =>  <HeadingMd h={3} title={children} layoutClassName={styles.subheading} />,
    blockquote: ({ children }) =>  <Quote layoutClassName={styles.quote}>{children}</Quote>,
  },
  list: {
    bullet: ({ children }) => <Checklist>{children}</Checklist>
  },
  listItem: ({ children }) => <>{children}</>,
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <LinkText target={value.openInNewTab && '_blank'} href={value.href} dataX='link'>{children}</LinkText>,
    internalLink: ({ value, children }) => value.ref
      ? <LinkText target={value.openInNewTab && '_blank'} href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</LinkText>
      : <>{children}</>,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsFooterContent = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    heading: ({ children }) => <HeadingLg h={2} title={children} layoutClassName={styles.heading} />,
    subheading: ({ children }) =>  <HeadingMd h={3} title={children} layoutClassName={styles.subheading} />,
    blockquote: ({ children }) =>  <Quote layoutClassName={styles.quote}>{children}</Quote>,
  },
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <LinkText target={value.openInNewTab && '_blank'} href={value.href} dataX='link'>{children}</LinkText>,
    internalLink: ({ value, children }) => value.ref
      ? <LinkText target={value.openInNewTab && '_blank'} href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</LinkText>
      : <>{children}</>,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsSimple = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    blockquote: ({ children }) =>  <Quote>{children}</Quote>,
  },
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
  },
}

/** @type {import('@portabletext/react').PortableTextProps['components']} */
export const componentsSimpleWithLinks = {
  block: {
    normal: ({ children }) => <p className={styles.paragraph}>{children}</p>,
    blockquote: ({ children }) =>  <Quote>{children}</Quote>,
  },
  marks: {
    strong: (props) => <strong className={styles.strong}>{props.children}</strong>,
    em: (props) => <em className={styles.em}>{props.children}</em>,
    externalLink: ({ value, children }) => <LinkText target={value.openInNewTab && '_blank'} href={value.href} dataX='link'>{children}</LinkText>,
    internalLink: ({ value, children }) => value.ref
      ? <LinkText href={determineDocumentPathSync({ document: value.ref, routeMap })} dataX='link'>{children}</LinkText>
      : <>{children}</>,
  },
}
