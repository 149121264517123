import styles from './Link.css'

export function Link({ href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.component, layoutClassName)}
      {...{ href, dataX, target }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkText({ href, dataX, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase
      className={cx(styles.componentText, layoutClassName)}
      {...{ href, dataX, target }}
    >
      {children}
    </LinkBase>
  )
}

export function LinkMenu({ href, dataX, dataXContext = undefined, children, layoutClassName = undefined, target = undefined }) {
  return (
    <LinkBase className={cx(styles.componentMenu, layoutClassName)} {...{ href, dataX, dataXContext, target }}>
      {children}
    </LinkBase>
  )
}

function LinkBase({ href, dataX, dataXContext = undefined, children, target = undefined, className = undefined }) {
  const rel = target === '_blank' ? 'noopener noreferrer' : undefined
  return (
    <a
      data-x={dataX}
      data-x-context={dataXContext}
      className={cx(styles.componentBase, className)}
      {...{ href, rel, target }}
    >
      {children}
    </a>
  )
}
